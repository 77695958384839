import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FiMenu, FiX } from 'react-icons/fi'; // For burger menu icons

const NavBar: React.FC = () => {
  const { user, isAuthenticated, logout } = useAuth0();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Helper function to get user initials
  const getUserInitials = () => {
    if (user && user.name) {
      const nameParts = user.name.split(' ');
      return `${nameParts[0][0].toUpperCase()}${nameParts[1]?.[0]?.toUpperCase() || ''}`;
    }
    return 'U'; // Default initial if no name is available
  };

  return (
    <header className="bg-white border-b shadow-lg p-4 flex justify-between items-center">
      <div className="flex items-center">
        <img src="/calogo.png" alt="App Logo" className="h-10" />
        <h1 className="ml-4 text-xl font-bold text-gray-700">x PD - Prozess Analyse</h1>
      </div>

      {/* Burger/Profile Menu */}
      <div className="flex items-center space-x-4">
        {isAuthenticated && (
          <div className="w-8 h-8 bg-gray-300 text-white flex items-center justify-center rounded-full">
            {getUserInitials()}
          </div>
        )}
        <button onClick={toggleMenu} className="focus:outline-none">
          {isMenuOpen ? <FiX className="w-6 h-6" /> : <FiMenu className="w-6 h-6" />}
        </button>
        {isMenuOpen && (
          <div className="absolute top-16 right-0 w-48 bg-white shadow-lg rounded-lg py-2 z-50">
            <nav className="flex flex-col space-y-2">
              <a href="/" className="block px-4 py-2 hover:bg-gray-100">Home</a>
              {isAuthenticated && user ? (
                <>
                  <a href="/profile" className="block px-4 py-2 hover:bg-gray-100">Profile</a>
                  <button
                    onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <a href="/login" className="block px-4 py-2 bg-blue-500 text-white rounded text-center">Login</a>
              )}
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};

export default NavBar;
