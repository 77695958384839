import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';
import { toPng } from 'html-to-image';

interface MermaidDiagramProps {
  chart: string;
  onDiagramGenerated: (base64Png: string) => void; // Prop to pass PNG data
}

const MermaidDiagram: React.FC<MermaidDiagramProps> = ({ chart, onDiagramGenerated }) => {
  const diagramRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    mermaid.initialize({ startOnLoad: false });
  }, []);

  useEffect(() => {
    const renderChart = async () => {
      if (diagramRef.current) {
        try {
          const id = 'mermaid-chart-' + Math.floor(Math.random() * 1000000);
          const { svg } = await mermaid.render(id, chart);
          diagramRef.current.innerHTML = svg;

          // Generate PNG after rendering the SVG using html-to-image
          generatePng();
        } catch (error: any) {
          diagramRef.current.innerHTML = `<div class="text-red-500">Error rendering diagram: ${error.message}</div>`;
        }
      }
    };

    const generatePng = () => {
      if (diagramRef.current) {
        // Use html-to-image to generate PNG
        toPng(diagramRef.current)
          .then((dataUrl) => {
            onDiagramGenerated(dataUrl); // Pass the base64 PNG to parent
          })
          .catch((error) => {
            console.error('Error generating PNG:', error);
          });
      }
    };

    renderChart();
  }, [chart, onDiagramGenerated]);

  return <div ref={diagramRef} className="mermaid" />;
};

export default MermaidDiagram;
