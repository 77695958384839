// src/App.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import MainLayout from './components/MainLayout';
import ProtectedRoute from './components/ProtectedRoute'; // A route wrapper for protected routes
import "./App.css";
import ProfilePage from './components/ProfilePage';

const App: React.FC = () => {
  return (
    <Routes>
      {/* Public Landing Page */}
      <Route path="/" element={<LandingPage />} />

      {/* Protected Main Layout */}
      <Route path="/dashboard" element={<ProtectedRoute component={MainLayout} />} />
      <Route path="/profile" element={<ProtectedRoute component={ProfilePage} />} />
    </Routes>
  );
};

export default App;
