import React, { useState } from 'react';
import axios from 'axios';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { AudioRecorder } from 'react-audio-voice-recorder';
import { v4 as uuidv4 } from 'uuid'; // For generating UUIDs
import { useAuth0 } from '@auth0/auth0-react'; // To get Auth0 user ID

interface ChatBoxProps {
  onFileContentUpdate: (content: string) => void;
  selectedProvider: string; // Added prop to track selected provider
}

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

interface PdApiRequest {
  id: string;
  user_id: string;
  doc_s3_path: string;
  agency_name: string;
  model_provider: string;
  llm_model_name: string;
  privacy_level: string;
  metadata: {
    datetime_request: string;
    datetime_response: string;
  };
}

interface PdApiResponse {
  id: string;
  metadata: {
    datetime_request: string;
    datetime_response: string;
  };
  response_quality_score: number;
  mermaid_code: string;
  message: string;
}

const ChatBox: React.FC<ChatBoxProps> = ({ onFileContentUpdate, selectedProvider }) => {
  const { user } = useAuth0();
  const [input, setInput] = useState(''); // Text input state
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value); // Update input field with user input
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      setInput(''); // Clear input field
    } else {
      setSelectedFile(null);
    }
  };

  // Handle audio recording complete
  const handleAudioData = async (blob: Blob) => {
    console.log('Audio recording saved', blob);

    const formData = new FormData();
    formData.append('audio', blob, 'recording.wav');

    try {
      setIsLoading(true);

      // Send the audio file to the Cloudflare Worker
      const response = await axios.post(
        'https://ca-audio-service.marcel-gocke.workers.dev',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const aiResponse = response.data;

      // Check if the response was successful and get the transcript
      if (aiResponse.response.success && aiResponse.response.result?.text) {
        const transcript = aiResponse.response.result.text;

        // Update the input field (textarea) with the transcript
        setInput(transcript || '');

        const assistantMessage: Message = {
          role: 'assistant',
          content: 'Audio processed and transcript received. Review and submit to generate the diagram.',
        };

        setMessages((prevMessages) => [...prevMessages, assistantMessage]);
      } else {
        throw new Error('Failed to process audio');
      }
    } catch (error) {
      console.error('Error processing the audio:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: 'Error processing the audio.' },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to upload file to S3 and call the PD API
  const processPdApi = async () => {
    if (!selectedFile || !user) return;

    try {
      setIsLoading(true);

      const formData = new FormData();
      formData.append('file', selectedFile);

      // Step 1: Upload file to S3
      const uploadResponse = await axios.post('https://cas3upload.marcel-gocke.workers.dev/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { fileName } = uploadResponse.data;

      // Step 2: Prepare PD API request
      const pdApiRequest: PdApiRequest = {
        id: uuidv4(),
        user_id: user.sub || '1', // Use Auth0 user ID
        doc_s3_path: fileName, // Path returned by S3 upload
        agency_name: 'bezirksamt',
        model_provider: 'openai',
        llm_model_name: 'gpt-4',
        privacy_level: 'high',
        metadata: {
          datetime_request: new Date().toISOString(),
          datetime_response: '',
        },
      };

      // Step 3: Call PD API
      const pdApiResponse = await axios.post<PdApiResponse>('http://18.197.39.236:8000/document', pdApiRequest, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const { mermaid_code, message } = pdApiResponse.data;

      // Set Mermaid diagram with the received code
      onFileContentUpdate(mermaid_code);

      const assistantMessage: Message = {
        role: 'assistant',
        content: message,
      };

      setMessages((prevMessages) => [...prevMessages, assistantMessage]);
    } catch (error) {
      console.error('Error processing PD API:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: 'Error processing PD API.' },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (isLoading) return;

    if (selectedProvider === 'PD' && selectedFile) {
      // Call PD API workflow
      await processPdApi();
    } else if (input.trim() !== '' && selectedProvider === 'CloudAhead') {
      // Call CloudAhead API (same logic as before)
      const userMessage: Message = { role: 'user', content: input };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      try {
        onFileContentUpdate(input); // Pass the content to generate the diagram
      } catch (error) {
        console.error('Error generating the diagram:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'assistant', content: 'Error generating the diagram.' },
        ]);
      }
    }
  };

  return (
    <div className="fixed bottom-0 left-0 w-full">
      <div className="flex justify-center">
        <button
          onClick={() => setIsChatHistoryOpen(!isChatHistoryOpen)}
          className="bg-gray-200 rounded-t-lg px-4 py-2 flex items-center"
        >
          {isChatHistoryOpen ? (
            <>
              <FiChevronDown className="mr-2" /> Hide Chat History
            </>
          ) : (
            <>
              <FiChevronUp className="mr-2" /> Show Chat History
            </>
          )}
        </button>
      </div>

      {isChatHistoryOpen && (
        <div
          className="absolute bottom-full left-0 w-full max-h-64 overflow-y-auto bg-white shadow-md"
          style={{ zIndex: 1000 }}
        >
          <div className="p-4">
            {messages.map((msg, index) => (
              <div key={index} className={`mb-2 ${msg.role === 'user' ? 'text-right' : 'text-left'}`}>
                <span
                  className={`inline-block p-2 rounded-lg ${
                    msg.role === 'user' ? 'bg-green-200' : 'bg-blue-200'
                  }`}
                >
                  {msg.content}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="flex items-center bg-white border-t border-gray-300 p-2 space-x-2">
        {/* File Upload for PD */}
        {selectedFile ? (
          <div className="flex items-center flex-grow">
            <p className="truncate">{selectedFile.name}</p>
            <button
              onClick={() => setSelectedFile(null)}
              className="ml-2 text-red-500 hover:text-red-700"
            >
              Remove
            </button>
          </div>
        ) : (
          <>
            {selectedProvider === 'PD' ? (
              <div className="flex items-center space-x-2">
                <input
                  type="file"
                  accept=".txt"
                  onChange={handleFileChange}
                  className="hidden"
                  id="file-input"
                />
                <label
                  htmlFor="file-input"
                  className="bg-gray-300 text-white px-4 h-10 flex items-center cursor-pointer"
                >
                  Upload File
                </label>
              </div>
            ) : (
              // Text input for CloudAhead
              <>
                <input
                  type="text"
                  value={input}
                  onChange={handleInputChange}
                  className="flex-grow h-10 border border-gray-300 rounded-l-lg p-2"
                  placeholder="Type or paste your message..."
                  disabled={isLoading} // Disable input while audio is being processed
                />
              </>
            )}
          </>
        )}

        {/* Audio Recorder for CloudAhead */}
        {selectedProvider === 'CloudAhead' && (
          <div className="flex items-center">
            <AudioRecorder onRecordingComplete={handleAudioData} />
          </div>
        )}

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          className="bg-green-300 text-white px-4 h-10 rounded-r-lg"
          disabled={isLoading || (selectedProvider === 'PD' && !selectedFile)}
        >
          {isLoading ? 'Loading...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
