import React, { useState } from 'react';
import ChatBox from './ChatBox';
import TCModal from './TCModal';
import MermaidDiagram from './MermaidDiagram';
import axios from 'axios';
import NavBar from './NavBar';

const MainLayout: React.FC = () => {
  const defaultDiagram = `
    graph TD;
    A[Start] --> B{Is it sunny?};
    B -->|Yes| C[Go outside];
    B -->|No| D[Stay inside];
    C --> E[Enjoy the day];
    D --> E;
  `;


  const [fileContent, setFileContent] = useState<string>(defaultDiagram);
  const [showModal, setShowModal] = useState<boolean>(true);
  const [email, setEmail] = useState<string>(''); 
  const [diagramPng, setDiagramPng] = useState<string>(''); 

  // State for provider selection (CloudAhead or PD)
  const [selectedProvider, setSelectedProvider] = useState<string>('PD'); // Default is 'PD'

  const [isProcessProviderCollapsed, setIsProcessProviderCollapsed] = useState<boolean>(false);
  const [isEmailCollapsed, setIsEmailCollapsed] = useState<boolean>(false);
  const [isCloudProviderCollapsed, setIsCloudProviderCollapsed] = useState<boolean>(false);
  const [isLLMModelCollapsed, setIsLLMModelCollapsed] = useState<boolean>(false);

  const handleFileContentUpdate = (content: string) => {
    setFileContent(content);
  };

  const handleAccept = () => {
    setShowModal(false);
    localStorage.setItem('acceptedTnC', 'true');
  };

  const handleReject = () => {
    alert('You must accept the T&C to use this application.');
  };

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Email submitted:', email);

    try {
      await axios.post('/api/send-email', {
        email,
        diagramPng,
      });
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }
  };

  // Function to render collapsible box content
  const renderCollapsibleBox = (
    title: string,
    isCollapsed: boolean,
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
    children: React.ReactNode,
    isDisabled: boolean = false
  ) => {
    return (
      <div className={`bg-white p-4 shadow-md rounded-lg ${isDisabled ? 'opacity-50' : ''}`}>
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-sm font-bold">{title}</h3>
          {!isDisabled && (
            <button
              className="text-xs text-blue-500"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? '▼' : '▲'}
            </button>
          )}
        </div>
        {!isCollapsed && !isDisabled && (
          <div className="mt-3 text-sm text-gray-500">
            {children}
          </div>
        )}
      </div>
    );
  };

  // Handle provider selection toggle between CloudAhead and PD
  const handleProviderSelection = (provider: string) => {
    setSelectedProvider(provider);
    if (provider === 'PD') {
      setIsCloudProviderCollapsed(true);
      setIsLLMModelCollapsed(true);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 relative">
      {showModal && <TCModal onAccept={handleAccept} onReject={handleReject} />}

      <NavBar />

      <main className="container mx-auto p-8 grid grid-cols-1 md:grid-cols-[80%_20%] gap-8 min-h-[calc(100vh-96px)] pb-24">
        <div className="flex flex-col h-full overflow-hidden">
          <div className="bg-white p-6 shadow-md rounded-lg flex-grow overflow-y-auto relative">
            <MermaidDiagram chart={fileContent} onDiagramGenerated={setDiagramPng} />
          </div>
        </div>

        <div className="space-y-5">
          {renderCollapsibleBox(
            'Process Provider',
            isProcessProviderCollapsed,
            setIsProcessProviderCollapsed,
            <>
              <p>Wählen Sie zwischen CloudAhead und PD</p>
              <div className="flex flex-wrap gap-2 mt-2">
                <button
                  className={`px-2 py-1 rounded-full ${selectedProvider === 'CloudAhead' ? 'bg-green-300' : 'bg-gray-200'}`}
                  onClick={() => handleProviderSelection('CloudAhead')}
                >
                  CloudAhead
                </button>
                <button
                  className={`px-2 py-1 rounded-full ${selectedProvider === 'PD' ? 'bg-green-300' : 'bg-gray-200'}`}
                  onClick={() => handleProviderSelection('PD')}
                >
                  PD
                </button>
              </div>
            </>
          )}

          {renderCollapsibleBox(
            'Diagram per Email senden',
            isEmailCollapsed,
            setIsEmailCollapsed,
            <>
              <form onSubmit={handleEmailSubmit}>
                <div className="mb-4">
                  <input
                    type="email"
                    id="email"
                    className="mt-1 p-2 w-full border border-gray-300 rounded"
                    placeholder="Email eingeben"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600">
                  senden
                </button>
              </form>
            </>
          )}

          {renderCollapsibleBox(
            'Cloud-Anbieter auswählen',
            isCloudProviderCollapsed,
            setIsCloudProviderCollapsed,
            <>
              <p>Ihre Daten werden in der AWS Region Frankfurt West gespeichert.</p>
              <div className="flex flex-wrap gap-2 mt-2">
                <button className="bg-green-300 px-2 py-1 rounded-full">AWS Deutschland</button>
                <button className="bg-gray-200 px-2 py-1 rounded-full">Delos</button>
                <button className="bg-gray-200 px-2 py-1 rounded-full">IONOS</button>
                <button className="bg-gray-200 px-2 py-1 rounded-full">StackIT</button>
              </div>
            </>,
            selectedProvider !== 'CloudAhead' // Disable if CloudAhead is not selected
          )}

          {renderCollapsibleBox(
            'LLM Modell auswählen',
            isLLMModelCollapsed,
            setIsLLMModelCollapsed,
            <>
              <p>Wählen Sie ein Modell für die Verarbeitung.</p>
              <div className="flex flex-wrap gap-2 mt-2">
                <button className="bg-green-300 px-2 py-1 rounded-full">GPT 4</button>
                <button className="bg-gray-200 px-2 py-1 rounded-full">Llama</button>
                <button className="bg-gray-200 px-2 py-1 rounded-full">Groq</button>
                <button className="bg-gray-200 px-2 py-1 rounded-full">Mistral</button>
              </div>
            </>,
            selectedProvider !== 'CloudAhead' // Disable if CloudAhead is not selected
          )}
        </div>
      </main>

      <ChatBox 
        onFileContentUpdate={handleFileContentUpdate} 
        selectedProvider={selectedProvider}  // Pass selected provider
      />
    </div>
  );
};

export default MainLayout;
