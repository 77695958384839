import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './LandingPage.css'; // Import the CSS for the gradient

const LandingPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="landing-page-container">
      {/* Header Section */}
      <header className="flex justify-between items-center py-8 px-8">
        <div className="flex items-center">
          <img src="/calogo.png" alt="App Logo" className="h-10" />
          <h1 className="ml-4 text-xl font-bold text-gray-700">x PD - Process Analysis Automator</h1>
        </div>

        {/* Log In Button positioned on the right */}
        <button
          onClick={() => loginWithRedirect()}
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Log In
        </button>
      </header>

      {/* Pricing Section */}
      <section className="header-gradient pricing-section bg-gray-100 py-16">
        <h2 className="text-3xl font-bold text-center mb-8">Start for free; Grow at any time.</h2>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          {/* Free Plan */}
          <div className="pricing-card bg-white p-6 rounded-lg shadow-md flex flex-col justify-between">
            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">Free</h3>
              <p className="mb-4 text-gray-500">Ideal for individuals and small teams</p>
              <ul className="text-left mb-6 text-gray-700">
                <li className="mb-2">✔ 1 User</li>
                <li className="mb-2">✔ 2 Projects</li>
                <li className="mb-2">✔ Access to basic features</li>
              </ul>
            </div>
            <button
              onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })}
              className="bg-blue-500 px-6 py-3 rounded mt-auto"
            >
              Start for Free
            </button>
          </div>

          {/* Business Plan */}
          <div className="pricing-card bg-white p-6 rounded-lg shadow-md flex flex-col justify-between">
            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">Business</h3>
              <p className="mb-4 text-gray-500">For small to medium-sized businesses</p>
              <ul className="text-left mb-6 text-gray-700">
                <li className="mb-2">✔ 10 Users</li>
                <li className="mb-2">✔ Unlimited Projects</li>
                <li className="mb-2">✔ Access to advanced features</li>
                <li className="mb-2">✔ Priority support</li>
              </ul>
            </div>
            <button
              onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })}
              className="bg-green-500 px-6 py-3 rounded mt-auto"
            >
              Choose Business
            </button>
          </div>

          {/* Enterprise Plan */}
          <div className="pricing-card bg-white p-6 rounded-lg shadow-md flex flex-col justify-between">
            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">Enterprise</h3>
              <p className="mb-4 text-gray-500">For large organizations and enterprises</p>
              <ul className="text-left mb-6 text-gray-700">
                <li className="mb-2">✔ Unlimited Users</li>
                <li className="mb-2">✔ Unlimited Projects</li>
                <li className="mb-2">✔ Full feature access</li>
                <li className="mb-2">✔ Dedicated account manager</li>
                <li className="mb-2">✔ Custom integrations and training</li>
              </ul>
            </div>
            <button
              onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })}
              className="bg-green-500 px-6 py-3 rounded mt-auto"
            >
              Contact Us
            </button>
          </div>
        </div>
      </section>

      {/* Additional Info Section */}
      <section className="value-proposition bg-white py-16">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-8">
            Revolutionize Your Process Documentation with Our AI based Process Intelligence.
          </h2>
          <p className="text-center mb-4 text-gray-600">
            Our tool makes process documentation and digitization simple and efficient.
          </p>
          <ul className="text-left list-disc list-inside text-gray-700 max-w-2xl mx-auto">
            <li className="mb-2">Interviews: Interview your key staff members for half an hour each.</li>
            <li className="mb-2">Upload: Upload the interviews into our tool.</li>
            <li className="mb-2">Process Diagram: Let the AI create integrated documentation from the interviews.</li>
            <li className="mb-2">Process Optimization: Optimize the automated documentation as you see fit.</li>
            <li className="mb-2">Digitization: Let the AI generate software code from your optimized processes.</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
